<template>
  <div class="dept">
    <el-table
      ref="multipleTable"
      row-key="label"
      :data="tableData"
      :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
      show-checkbox
      tooltip-effect="dark"
      style="width: 100%"
      height="5.20833vw"
      v-table-auto-height
    ><!--v-loading="loading"-->
      <el-table-column type="selection" width="55"> </el-table-column>
      <el-table-column prop="name" label="部门名称" width="200">
        <template slot-scope="scope">
          {{ scope.row.name }}
        </template>
      </el-table-column>
      <el-table-column prop="description" label="备注" align="center">
        <template slot-scope="scope">
          {{ scope.row.remark }}
        </template>
      </el-table-column>
      <el-table-column
        label="操作"
        align="center"
        width="200"
        show-overflow-tooltip
      >
        <template slot-scope="scope">
          <el-button
            size="mini"
            type="primary"
            plain
            icon="el-icon-s-tools"
            v-if="isUpdate"
            @click="limitBtn(scope.row)"
            >数据权限</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-dialog
      title="数据权限(设置该部门的用户可以操作的数据的范围)"
      :visible.sync="dialogVisible"
      width="35%"
      left
    >
      <!-- <el-radio-group> -->
      <div class="radio">
        <el-radio v-model="radio" label="0">
          <span class="radio-label-div"
            ><span>个人</span
            ><span class="detail">只能操作自己的数据</span></span
          >
        </el-radio>
        <el-radio v-model="radio" label="1">
          <span class="radio-label-div"
            ><span>所属部门</span
            ><span class="detail">能操作自己和自己所属部门的数据</span></span
          >
        </el-radio>
        <el-radio v-model="radio" label="2">
          <span class="radio-label-div"
            ><span>所属部门及下属本门</span
            ><span class="detail"
              >能操作自己、下属和自己所属部门及其子部门的数据</span
            ></span
          >
        </el-radio>
        <el-radio v-model="radio" label="3">
          <span class="radio-label-div"
            ><span>全部</span><span class="detail">能操作全部的数据</span></span
          >
        </el-radio>
      </div>
      <!-- </el-radio-group> -->
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancel" :loading="buttonLoading">取 消</el-button>
        <el-button type="primary" @click="conserve" :loading="buttonLoading">保存</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import LTable from "@/components/tableComponent/index.vue";
import { mapGetters, mapState } from "vuex";
import { getPermissionButton } from "@/utils/getUrlPermissionButtons";
export default {
  components: {
    LTable,
  },
  data() {
    return {
      dialogVisible: false,
      loading: false,
      officeLabel: "",
      radio: "",
      tableData: [],
      permissionButtonList: [], //权限按钮list
      isUpdate: false,
      buttonLoading: false //按钮loading
    };
  },
  computed: {
    ...mapGetters(["permissions"]),
    ...mapState({
      menuList: (state) => state.common.menuList,
    }),
  },
  watch: {
    permissionButtonList: {
      handler(val){
        if(val){
          this.isUpdate =
            val.indexOf("dataAuth:config") != -1;
        }
      },
      deep: true
    },
    menuList: {
      handler(val){
        if(val){
          this.getPermissionButton(this.$route.path, (data) => {
            this.permissionButtonList = data;
          });
        }
      },
      deep: true
    }
  },
  created() {
    this.init();
    //menulist一直在时这个方法不可缺少
    this.getPermissionButton(this.$route.path, (data) => {
      this.permissionButtonList = data;
    });
  },
  methods: {
    getPermissionButton,
    /**初始化 */
    init() {
      this.loading = true;
      this.$api.getDept().then((res) => {
        this.loading = false;
        if (res.data.code == "0") {
          this.tableData = res.data.data;
          console.log(res.data.data);
        } else {
          this.tableData = [];
        }
      });
    },
    conserve() {
      this.buttonLoading = true
      let params = {
        officeLabel: this.officeLabel,
        authType: this.radio,
      };
      this.$api.eduauthData(params).then((res) => {
        if (res.data.code == "0") {
          this.$message({
            message: "操作成功",
            type: 'success',
            onClose: () => {
              this.cancel()
            }
          })
        } else {
          this.buttonLoading = false
          this.$message.error(res.data.msg)
        }
      });
    },
    cancel() {
      this.dialogVisible = false;
      this.radio = "";
      this.buttonLoading = false
    },
    limitBtn(row) {
      this.dialogVisible = true;
      this.$nextTick(() => {
        this.officeLabel = row.label;
        this.$api.getByDeptLabel({ label: row.label }).then((res) => {
          if (res.data.code === 0) {
            this.radio = res.data.data.authType;
          } else {
            this.$message.error(res.data.msg);
          }
        });
      });
    },
  }
};
</script>

<style lang="scss" scoped>
.dept {
  background-color: #ffffff;
  height: calc(100% - 40px);
  padding: 20px;
  .el-dialog {
    position: relative;
    .radio {
      margin: 15px 20px;
      display: flex;
      flex-direction: column;
      .el-radio {
        margin-bottom: 20px;
        .radio-label-div {
          display: inline-block;
          width: calc(100% - 14px);
          span {
            &:first-child {
              display: inline-block;
              width: 150px;
            }
          }
          .detail {
            display: inline-block;
            vertical-align: text-top;
            width: calc(100% - 170px);
            margin-left: 20px;
            left: 170px;
            color: #909399;
            white-space: normal;
            word-break: break-all;
            line-height: 1.5;
          }
        }
      }
    }
  }

  .target-pagination {
    padding: 20px 0;
  }
}
</style>
